var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"card-title-desc"}),_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.formWage.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 col-sm-5"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v("* ")]),_c('label',{attrs:{"for":"branchId"}},[_vm._v("สาขา:")]),_c('multiselect',{class:{
                      'is-invalid': _vm.submitform && _vm.$v.form.branchId.$error,
                    },attrs:{"id":"branchId","options":_vm.localDataBranch,"track-by":"name","label":"nameTh","show-labels":false},model:{value:(_vm.form.branchId),callback:function ($$v) {_vm.$set(_vm.form, "branchId", $$v)},expression:"form.branchId"}}),(_vm.submitform && _vm.$v.form.branchId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.branchId.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-3 col-sm-6"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"wageCode"}},[_vm._v("รหัสค่าแรง:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.wageCode),expression:"form.wageCode"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitform && _vm.$v.form.wageCode.$error,
                    },attrs:{"id":"wageCode","type":"text","value":"Otto"},domProps:{"value":(_vm.form.wageCode)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "wageCode", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.wageCode.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.wageCode.maxLength)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-3 col-sm-6"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v("* ")]),_c('label',{attrs:{"for":"nameTh"}},[_vm._v("ชื่อค่าแรง (ภาษาไทย):")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nameTh),expression:"form.nameTh"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitform && _vm.$v.form.nameTh.$error,
                    },attrs:{"id":"nameTh","type":"text","value":"Otto"},domProps:{"value":(_vm.form.nameTh)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nameTh", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.nameTh.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.nameTh.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-3 col-sm-6"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"nameEn"}},[_vm._v("ชื่อค่าแรง (ภาษาอังกฤษ):")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nameEn),expression:"form.nameEn"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.submitform && _vm.$v.form.nameEn.$error,
                    },attrs:{"id":"nameEn","type":"text","value":"Otto"},domProps:{"value":(_vm.form.nameEn)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nameEn", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.nameEn.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.nameEn.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"maxDcPer"}},[_vm._v("ส่วนลดสูงสุด(%):")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.maxDcPer),expression:"form.maxDcPer"}],staticClass:"form-control text-end",class:{
                        'is-invalid': _vm.submitform && _vm.$v.form.maxDcPer.$error,
                      },attrs:{"id":"maxDcPer","min":"0","max":"100","placeholder":""},domProps:{"value":(_vm.form.maxDcPer)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "maxDcPer", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.maxDcPer.$error)?_c('div',{staticClass:"invalid-tooltip"},[(
                          !_vm.$v.form.maxDcPer.maxValue ||
                          !_vm.$v.form.maxDcPer.minValue
                        )?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"maxDcAmount"}},[_vm._v("ส่วนลดสูงสุด(บาท):")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.maxDcAmount),expression:"form.maxDcAmount"}],staticClass:"form-control text-end",class:{
                        'is-invalid':
                          _vm.submitform && _vm.$v.form.maxDcAmount.$error,
                      },attrs:{"min":1,"id":"maxDcAmount","placeholder":""},domProps:{"value":(_vm.form.maxDcAmount)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "maxDcAmount", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.maxDcAmount.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.maxDcAmount.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v("* ")]),_c('label',{attrs:{"for":"wageType"}},[_vm._v("ประเภทค่าแรง:")]),_c('multiselect',{class:{
                      'is-invalid': _vm.submitform && _vm.$v.form.wageType.$error,
                    },attrs:{"id":"wageType","options":_vm.optionsWage,"track-by":"name","label":"name","show-labels":false},on:{"select":_vm.dis},model:{value:(_vm.form.wageType),callback:function ($$v) {_vm.$set(_vm.form, "wageType", $$v)},expression:"form.wageType"}}),(_vm.submitform && _vm.$v.form.wageType.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.wageType.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"price"}},[_vm._v("ค่าแรงต่อชั่วโมง(ครั้ง):")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.price),expression:"form.price"}],staticClass:"form-control text-end",class:{
                        'is-invalid': _vm.submitform && _vm.$v.form.price.$error,
                      },attrs:{"disabled":_vm.isDisable,"precision":2,"id":"price","placeholder":""},domProps:{"value":(_vm.form.price)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "price", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.price.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.price.numeric)?_c('span',[_vm._v(_vm._s(_vm.err))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-2 col-sm-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"workDuration"}},[_vm._v("ระยะเวลาทำงาน(ชั่วโมง):")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.workDuration),expression:"form.workDuration"}],staticClass:"form-control text-end",class:{
                        'is-invalid':
                          _vm.submitform && _vm.$v.form.workDuration.$error,
                      },attrs:{"disabled":_vm.isDisabled,"id":"workDuration","type":"text","placeholder":""},domProps:{"value":(_vm.form.workDuration)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "workDuration", $event.target.value)}}}),(_vm.submitform && _vm.$v.form.workDuration.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.form.workDuration.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md"},[_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.form.useInBp),callback:function ($$v) {_vm.$set(_vm.form, "useInBp", $$v)},expression:"form.useInBp"}},[_vm._v("ใช้กับศูนย์บริการ")]),_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.form.useInGs),callback:function ($$v) {_vm.$set(_vm.form, "useInGs", $$v)},expression:"form.useInGs"}},[_vm._v("ใช้กับศูนย์ซ่อมสีและตัวถัง")])],1)])]),_c('br'),_c('div',{staticClass:"col-md"},[_c('button',{staticClass:"btn btn-success float-end",attrs:{"type":"submit"}},[_vm._v(" บันทึก ")])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }